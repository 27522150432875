body {
  margin: 0;
  font-family: "Quicksand", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}
@media (max-width: 768px) {
  body {
    width: 100vw;
    height: 100vh;
    padding-top: 0;
  }
}

::-webkit-scrollbar {
  width: 10px;
}
@media (max-width: 768px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

::-webkit-scrollbar-track {
  background: #43494e;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}