.MenuBackground {
  height: 72vh;
  position: relative;
}
.MenuBackground .MenuDark,
.MenuBackground .MenuLight {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.MenuBackground .MenuDark .Item,
.MenuBackground .MenuDark .ItemAtivo,
.MenuBackground .MenuDark .ItemSelecionado,
.MenuBackground .MenuLight .Item,
.MenuBackground .MenuLight .ItemAtivo,
.MenuBackground .MenuLight .ItemSelecionado {
  font-size: 1.2rem;
  padding: 10px 5px;
}
.MenuBackground .MenuDark .Item,
.MenuBackground .MenuLight .Item {
  cursor: not-allowed;
}
.MenuBackground .MenuDark .ItemSelecionado,
.MenuBackground .MenuLight .ItemSelecionado {
  background-color: #486948;
  color: #fff;
  font-size: 0.9rem;
  cursor: pointer;
}
.MenuBackground .MenuDark .ItemSelecionado:hover,
.MenuBackground .MenuLight .ItemSelecionado:hover {
  background-color: #598059;
  color: #fff;
}
.MenuBackground .MenuDark .ItemAtivo,
.MenuBackground .MenuLight .ItemAtivo {
  border-left: 6px solid #c34858;
  border-right: 6px solid #c34858;
  cursor: default;
}
.MenuBackground .MenuDark {
  border: 1px solid #fff;
}
.MenuBackground .MenuDark .Item,
.MenuBackground .MenuDark .ItemAtivo,
.MenuBackground .MenuDark .ItemSelecionado {
  border-bottom: 1px solid #fff;
}
.MenuBackground .MenuDark .Item {
  color: #4e5353;
}
.MenuBackground .MenuLight {
  border: 1px solid #d4d4d4;
}
.MenuBackground .MenuLight .Item,
.MenuBackground .MenuLight .ItemAtivo,
.MenuBackground .MenuLight .ItemSelecionado {
  border-bottom: 1px solid #d4d4d4;
}
.MenuBackground .MenuLight .Item {
  color: #d5d6d8;
}