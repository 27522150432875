.DeleteButton {
  color: #c34858;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.DeleteButton:hover {
  color: #ff3535;
}