.CardLight,
.CardDark {
  position: absolute;
  width: 40vw;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 768px) {
  .CardLight,
.CardDark {
    width: 90vw;
    margin: 20px 0 0 0;
  }
}

.CardLight {
  border: 1px solid #a9a9a9;
  background-color: #f7f7f7;
  -webkit-box-shadow: 0px 0px 31px -21px rgba(0, 0, 0, 0.69);
  -moz-box-shadow: 0px 0px 31px -21px rgba(0, 0, 0, 0.69);
  box-shadow: 0px 0px 31px -21px rgba(0, 0, 0, 0.69);
}

.CardDark {
  border: 1px solid #585858;
  -webkit-box-shadow: 0px -1px 34px 4px rgba(0, 0, 0, 0.69);
  -moz-box-shadow: 0px -1px 34px 4px rgba(0, 0, 0, 0.69);
  box-shadow: 0px -1px 34px 4px rgba(0, 0, 0, 0.69);
}

.ErrorMessage {
  font-size: medium;
  color: #c34858;
  text-align: right;
}

.Eye {
  position: absolute;
  right: 20px;
  top: 5px;
  z-index: 1;
}
.Eye:hover {
  cursor: pointer;
  color: darkgray;
}

.Rodape {
  padding-top: 5px;
  font-size: smaller;
}