.ModalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.45);
  z-index: 4;
}

.ModalDark,
.ModalLight {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  width: 52vw;
  min-height: 50vh;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 5px;
}
.ModalDark .Header,
.ModalLight .Header {
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 5px;
}
.ModalDark .Header .ModalClose,
.ModalLight .Header .ModalClose {
  position: absolute;
  right: 0;
  margin-right: 10px;
  z-index: 4;
  cursor: pointer;
  transition: color 0.2s;
}
.ModalDark .Header .ModalClose:hover,
.ModalLight .Header .ModalClose:hover {
  color: #c34858;
}
.ModalDark .ModalBody,
.ModalLight .ModalBody {
  padding: 10px 20px;
}

.ModalLight {
  background-color: #f8f9fa;
  color: #000;
}
.ModalLight .Header {
  background-color: #f8f9fa;
}

.ModalDark {
  background-color: #272828;
  color: #fff;
}
.ModalDark .Header {
  background-color: #272828;
}