.Conhecer {
  padding-top: 50px;
  height: 100%;
  width: 100%;
  background-color: #fff;
  color: #000;
  overflow-x: hidden;
}
@media (max-width: 768px) {
  .Conhecer {
    padding-top: 0;
  }
}

.Container1 {
  padding-top: 60px;
  text-align: center;
  background-color: #c34858;
  color: #fff;
}
.Container1 h1 {
  font-weight: 700;
}
@media (max-width: 768px) {
  .Container1 {
    padding: 10px 40px;
  }
  .Container1 h1 {
    font-size: 30px;
  }
  .Container1 h3 {
    font-size: 20px;
  }
}

.Container3 {
  color: black;
  padding: 0px 100px 80px 100px;
}
.Container3 h1 {
  font-size: 50px;
}
.Container3 h4 {
  font-weight: 700;
}
.Container3 span {
  font-size: large;
}
@media (max-width: 768px) {
  .Container3 {
    padding: 15px 40px;
    text-align: center;
  }
  .Container3 h1 {
    font-size: 35px;
  }
  .Container3 h4 {
    font-size: 20px;
  }
  .Container3 span {
    font-size: 15px;
  }
}

.Video {
  width: 960px;
  height: 500px;
}
@media (max-width: 768px) {
  .Video {
    width: 100%;
    height: auto;
  }
}