.Button {
  padding: 20px 90px;
  border-radius: 30px;
  border: 0;
  background-color: #fff;
  color: #2EA352;
  font-weight: 800;
  -webkit-box-shadow: 0px 0px 16px -2px rgba(0, 0, 0, 0.69);
  -moz-box-shadow: 0px 0px 16px -2px rgba(0, 0, 0, 0.69);
  box-shadow: 0px 0px 16px -2px rgba(0, 0, 0, 0.69);
}
.Button:hover {
  background-color: #f8f6fa;
}
@media (max-width: 768px) {
  .Button {
    white-space: nowrap;
    max-width: 100%;
    padding: 20px 20px;
  }
}

.Invert {
  background-color: #297941;
  color: #fff;
}
.Invert:hover {
  background-color: #2EA352;
}

.Small {
  padding: 10px 60px;
}