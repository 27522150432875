.Parent {
  position: relative;
  height: 70vh;
}
.Parent .Center {
  position: absolute;
  width: 100%;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.Parent .Center span {
  color: green;
  text-decoration: underline;
  cursor: pointer;
}