.Sucata {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.Sucata .DeleteIcon {
  position: absolute;
  left: 0;
  color: #c34858;
  cursor: pointer;
}
.Sucata .Container {
  width: 100%;
  position: relative;
  text-align: left;
}
.Sucata .Container .Icon {
  position: absolute;
  right: 0;
}

.Dados {
  display: none;
  background-color: rgba(26, 26, 26, 0.1);
}
.Dados.Active {
  display: table-row-group;
}