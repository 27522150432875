.ContextMenuDark,
.ContextMenuLight {
  position: fixed;
  min-width: 200px;
  max-width: 300px;
  z-index: 5;
  padding: 0;
  border-radius: 5px;
  text-align: center;
  list-style-type: none;
}
.ContextMenuDark li,
.ContextMenuLight li {
  padding: 4px 6px;
  border-bottom: 1px solid;
  border-radius: 5px;
  cursor: pointer;
}
.ContextMenuDark li:last-child,
.ContextMenuLight li:last-child {
  border: none;
}

.ContextMenuDark {
  background-color: #202225;
  border: 1px solid #575757;
  -webkit-box-shadow: 0px 0px 2px 1px rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 2px 1px rgba(255, 255, 255, 0.1);
}
.ContextMenuDark li:hover {
  background-color: #3c3f44;
}

.ContextMenuLight {
  background-color: #ececec;
  border: 1px solid #593196;
  -webkit-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
}
.ContextMenuLight li:hover {
  background-color: lightgray;
}