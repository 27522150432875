.Navbar {
  position: fixed;
  height: 60px;
  padding: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background-color: #3e3e3e;
}
@media (max-width: 768px) {
  .Navbar {
    width: 100%;
  }
}

.LogoDiv {
  text-align: center;
  padding: 5px 0;
}
@media (max-width: 768px) {
  .LogoDiv {
    display: none;
  }
}
.LogoDiv .Logo {
  height: 50px;
}

.IconLogo {
  color: white;
  font-size: 60px;
}

@media (max-width: 768px) {
  .NavigationItems {
    display: none;
  }
}

.Button {
  float: right;
  padding: 10px 0;
}
@media (max-width: 768px) {
  .Button {
    display: none;
  }
}

.Unificar {
  text-align: center;
  color: #fff;
  font-weight: 700;
  font-size: x-large;
  padding: 10px 0;
}
@media (min-width: 769px) {
  .Unificar {
    display: none;
  }
}