.OptionLight,
.OptionDark {
  position: relative;
  padding: 10px;
  border: 1px solid;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
  height: 100%;
  overflow: hidden;
  transition: background-color 0.2s ease-out;
}
.OptionLight:focus, .OptionLight:hover, .OptionLight:visited, .OptionLight:link, .OptionLight:active,
.OptionDark:focus,
.OptionDark:hover,
.OptionDark:visited,
.OptionDark:link,
.OptionDark:active {
  text-decoration: none;
}
.OptionLight .Beta,
.OptionDark .Beta {
  position: absolute;
  width: 100px;
  right: -25px;
  transform: rotate(45deg);
  background-color: #c34858;
  color: #fff;
}

.OptionLight:hover {
  background-color: #eeeeee;
}

.OptionDark:hover {
  background-color: #1d1f1f;
}

.Icon {
  width: 100%;
  padding: 0 200px;
}

.Text {
  text-align: center;
  font-size: 22px;
  padding: 15px 0;
}