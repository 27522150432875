.SideDrawer {
  height: 100vh;
  background-color: #363838;
  position: fixed;
  top: 50px;
  right: 0;
  bottom: 0;
  width: 70%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(0);
  transition: transform 0.3s ease-out;
}
.SideDrawer ul {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 10px 0;
}
@media (min-width: 768px) {
  .SideDrawer {
    display: none;
  }
}

.SideDrawerFechado {
  transform: translateX(100%);
}