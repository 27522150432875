.ItemDark,
.ItemLight {
  display: inline-block;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 8px 0;
  border: 1px solid;
  border-top: none;
  text-align: center;
  cursor: pointer;
}
.ItemDark:first-child,
.ItemLight:first-child {
  border-top: 1px solid;
}
.ItemDark input:checked + span,
.ItemLight input:checked + span {
  color: inherit;
  cursor: default;
}

.ItemLight {
  background-color: #f5f5f5;
  border-color: #000;
}
.ItemLight:hover {
  background-color: #d3d3d3;
}

.ItemDark {
  background-color: transparent;
  border-color: #fff;
}
.ItemDark:hover {
  background-color: #3b3e3e;
}

.Active {
  background-color: #b8b8b8;
  color: #000;
  cursor: default;
}
.Active:hover {
  background-color: #b8b8b8;
}