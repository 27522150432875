.Field {
  position: relative;
}
.Field .FieldInputLight,
.Field .FieldInputDark {
  display: inline-block;
  height: 40px;
  padding: 0px 10px;
  cursor: text;
  border: none;
  background-color: transparent;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: border-color 0.2s ease-out;
  will-change: border-color;
}
.Field .FieldInputLight::-webkit-input-placeholder,
.Field .FieldInputDark::-webkit-input-placeholder {
  opacity: 0.5;
  transition: opacity 0.2s ease-out;
}
.Field .FieldInputLight::-moz-placeholder,
.Field .FieldInputDark::-moz-placeholder {
  opacity: 0.5;
  transition: opacity 0.2s ease-out;
}
.Field .FieldInputLight:focus::-webkit-input-placeholder,
.Field .FieldInputDark:focus::-webkit-input-placeholder {
  color: #767676;
  opacity: 1;
  transition-delay: 0.2s;
}
.Field .FieldInputLight:focus::-moz-placeholder,
.Field .FieldInputDark:focus::-moz-placeholder {
  color: #767676;
  opacity: 1;
  transition-delay: 0.2s;
}
.Field .FieldInputDark {
  color: #fff;
}
.Field .IsValid {
  border: 2px solid #683ab786;
}
.Field .IsValid:focus {
  outline: none;
  border-color: #673ab7;
}
.Field .IsValidLight {
  border: 2px solid #6c757d;
}
.Field .IsValidLight:focus {
  outline: none;
  border-color: #838f99;
}
.Field .IsNotValid {
  border: 2px solid #c3485886;
}
.Field .IsNotValid:focus {
  outline: none;
  border-color: #c34858;
}

.LabelInputFileLight,
.LabelInputFileDark {
  padding: 4px 10px;
  border-radius: 5px;
  font-size: large;
  cursor: pointer;
  transition: background-color 0.2s;
}

.LabelInputFileLight {
  border: 1px #218838 solid;
  color: #218838;
}
.LabelInputFileLight:hover {
  background-color: #36a14d;
  color: #fff;
}

.LabelInputFileDark {
  background-color: #218838;
  color: #fff;
}
.LabelInputFileDark:hover {
  background-color: #36a14d;
}

.InputFile {
  display: none;
}

.TextRadio:focus {
  outline: none;
}

.AlignCenter {
  text-align: center;
}

.Disabled {
  background-color: gray !important;
  opacity: 30%;
  cursor: not-allowed;
}