.DeleteIcon {
  position: absolute;
  left: 2rem;
  color: #c34858;
  cursor: pointer;
}

.StateIcon {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  transition: all 0.5s;
}