.AnunciarLabel,
.AusenteLabel,
.RevisaoLabel {
  padding: 2px 10px;
  border-radius: 4px;
  margin: 0px 5px;
}
@media (max-width: 768px) {
  .AnunciarLabel,
.AusenteLabel,
.RevisaoLabel {
    display: none;
  }
}

.AnunciarLabel {
  background-color: #79cd5666;
}

.AusenteLabel {
  border: 1px solid #000;
}

.RevisaoLabel {
  background-color: #593196;
  color: #fff;
}

.SelectAll {
  position: fixed;
  top: 0;
  right: 0;
}