.TextareaLight,
.TextareaDark {
  background-color: transparent;
  border-color: #683ab786;
  width: 100%;
}
.TextareaLight::-webkit-input-placeholder,
.TextareaDark::-webkit-input-placeholder {
  opacity: 0.5;
  transition: opacity 0.2s ease-out;
}
.TextareaLight::-moz-placeholder,
.TextareaDark::-moz-placeholder {
  opacity: 0.5;
  transition: opacity 0.2s ease-out;
}
.TextareaLight:focus,
.TextareaDark:focus {
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
  border-color: #673ab7;
}
.TextareaLight:focus::-webkit-input-placeholder,
.TextareaDark:focus::-webkit-input-placeholder {
  color: #767676;
  opacity: 1;
  transition-delay: 0.2s;
}
.TextareaLight:focus::-moz-placeholder,
.TextareaDark:focus::-moz-placeholder {
  color: #767676;
  opacity: 1;
  transition-delay: 0.2s;
}

.TextareaLight {
  color: #000;
}

.TextareaDark {
  color: #fff;
}
.TextareaDark:focus {
  color: #fff;
  background-color: transparent;
}

.IsNotValid {
  border: 2px solid #c3485886;
}
.IsNotValid:focus {
  outline: none;
  border-color: #c34858;
}

.SaveButton {
  position: absolute;
  width: 40px;
  z-index: 1;
  height: 40px;
  right: 1px;
  top: 0;
  border-radius: 50px;
  background-color: #683ab786;
  color: white;
  border: 0;
}
.SaveButton:hover {
  border: 0;
  background-color: #673ab7;
}

.Spinner {
  position: absolute;
  width: 40px;
  z-index: 1;
  height: 40px;
  right: 1px;
  top: 0;
}

.NoResize {
  resize: none;
}