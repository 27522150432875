.BotaoWhatsapp {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 6;
  cursor: pointer;
  color: #25d466;
  transition: color 0.2s;
}
.BotaoWhatsapp:hover {
  text-decoration: none;
  color: #3bec7c;
}