.FormGroup {
  width: 100%;
  position: relative;
  margin-bottom: 15px;
  padding: 15px 0 0;
}

.FormField {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  font-size: 18px;
  color: inherit;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}
.FormField::placeholder {
  color: transparent;
}
.FormField:placeholder-shown ~ .FormLabel {
  font-size: 20px;
  cursor: text;
  top: 20px;
}
.FormField:focus {
  padding-bottom: 6px;
  border-width: 3px;
  border-image: linear-gradient(to right, #9367e6, #6f37d6);
  border-image-slice: 1;
}
.FormField:focus ~ .FormLabel {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 18px;
  color: #9367e6;
  font-weight: 700;
}
.FormField:focus ~ .Hint {
  display: block;
}

.Disable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.NA {
  position: absolute;
  transform: translateY(50%);
  right: 0;
  cursor: pointer;
}
.NA:hover {
  color: #9367e6;
}

.Hint {
  position: absolute;
  display: none;
  color: #9b9b9b;
}

.FormLabel {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #9b9b9b;
}

.Invalid {
  border-color: #c34858;
}

.Small {
  margin-bottom: 10px;
}
.Small .FormField {
  font-size: 0.8rem;
}
.Small .FormField::placeholder {
  font-size: 0.8rem;
}
.Small .FormField:placeholder-shown ~ .FormLabel {
  font-size: 0.9rem;
}
.Small .FormField:focus ~ .FormLabel {
  font-size: 1rem;
}
.Small .FormField:focus ~ .Hint {
  font-size: 0.8rem;
  display: block;
}
.Small .FormLabel {
  font-size: 0.9rem;
}

.Tooltip {
  position: absolute;
  transform: translateY(35%);
  right: 30px;
}