.Total {
  background-color: #79cd5650 !important;
  font-weight: 800;
  cursor: text !important;
}

.Somatoria {
  background-color: #ecc34d50;
}
.Somatoria:hover {
  background-color: #ecc34d80 !important;
}

.Subcategoria {
  width: 300px;
  text-align: left;
}

.DadoMensal {
  width: 80px;
}