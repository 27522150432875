.Purple {
  background-color: #59319650;
}
.Purple:hover {
  background-color: #59319620 !important;
}

.Green {
  background-color: #79cd5650;
}
.Green:hover {
  background-color: #79cd5620 !important;
}

.LogoMarca {
  max-height: 30px;
  max-width: 60px;
}