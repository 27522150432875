.Conhecer {
  padding-top: 50px;
  height: 100%;
  width: 100%;
  background-color: #fff;
  color: #000;
  overflow-x: hidden;
}
@media (max-width: 768px) {
  .Conhecer {
    padding-top: 0;
  }
}

.Container1 {
  padding: 70px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background-color: #59319680;
  color: #fff;
}
.Container1 h1 {
  font-weight: 700;
  text-shadow: 2px 2px #00000061;
}
.Container1 h2 {
  font-size: xx-large;
}
@media (max-width: 768px) {
  .Container1 h2 {
    font-size: x-large;
    margin-top: 20px;
    margin-bottom: 40px;
  }
}
.Container1 .Header {
  padding-bottom: 20px;
}
.Container1 .Header::after {
  display: block;
  margin: 20px auto 10px auto;
  width: 20%;
  height: 2px;
  content: "";
  background: #fff;
}
@media (max-width: 768px) {
  .Container1 .Header::after {
    margin: 20px auto 5px auto;
    width: 80%;
  }
}
@media (max-width: 768px) {
  .Container1 {
    padding: 20px 40px 50px 40px;
  }
  .Container1 h1 {
    font-size: 30px;
  }
  .Container1 h3 {
    font-size: 20px;
  }
}

.Container2 {
  padding: 30px 70px;
  text-align: center;
}
.Container2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 50px;
  top: -55px;
  background: #fff;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  transform: translate(-50%, 50%);
}
.Container2 h2 {
  font-weight: 700;
  color: #593196;
}
.Container2 h3 {
  font-weight: 700;
  color: #c34858;
}
.Container2 h3::after {
  display: block;
  margin: 20px auto 50px auto;
  width: 40px;
  height: 4px;
  content: "";
  background: #c34858;
}
@media (max-width: 768px) {
  .Container2 {
    padding: 20px 40px;
  }
  .Container2 h2 {
    font-size: 25px;
  }
  .Container2 h3 {
    font-size: 22px;
  }
  .Container2 h5 {
    font-size: 15px;
  }
}

.Container3 {
  background-color: #331c6390;
  color: #fff;
  padding: 100px 100px 80px 80px;
}
.Container3 h1 {
  font-size: 50px;
  text-shadow: 2px 2px #00000061;
}
.Container3 h4 {
  font-weight: 700;
  text-shadow: 2px 2px #00000061;
}
.Container3 span {
  font-size: large;
  text-shadow: 2px 2px #00000061;
}
@media (max-width: 768px) {
  .Container3 {
    padding: 10px 40px;
    text-align: center;
  }
  .Container3 h1 {
    font-size: 35px;
  }
  .Container3 h4 {
    font-size: 20px;
  }
  .Container3 span {
    font-size: 15px;
  }
}

.Container4 {
  padding: 50px 70px;
  text-align: center;
  background-color: #f3f3f3;
}
.Container4 h3 {
  font-weight: 700;
  color: #c34858;
}
.Container4 h3::after {
  display: block;
  margin: 20px auto 50px auto;
  width: 40px;
  height: 4px;
  content: "";
  background: #c34858;
}
@media (max-width: 768px) {
  .Container4 {
    padding: 50px 40px;
  }
}

@media (max-width: 768px) {
  .Imagem {
    display: none;
  }
}

.BackgroundImage {
  background: url("/images/sucatas.jpg") no-repeat fixed;
  background-size: 100% auto;
}
@media (max-width: 768px) {
  .BackgroundImage {
    background-size: auto 100%;
    background-position: center;
  }
}

.BackgroundColor {
  background-color: #593196;
  background-size: 100% auto;
}

.TextoBotao {
  font-size: larger !important;
}

.Video {
  width: 800px;
  height: 450px;
  margin-bottom: 50px;
}
@media (max-width: 768px) {
  .Video {
    width: 100%;
    height: auto;
  }
}