.Center {
    position: absolute;
    width: 100%;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;

    text-align: center;
}

.TituloBusca {
    padding: 0px;
    margin: 0;
    font-size: large;
    font-weight: 800;
}

.TextoBusca {
    font-size: larger;
    font-weight: 600;
}

.QuantidadeBusca {
    font-size: x-large;
    color: #6a5bcd;
}
