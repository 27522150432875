.Card {
  padding: 0 5px;
  text-align: center;
}

.Icon {
  font-size: 30px;
  color: #593196;
}

.Titulo {
  font-size: x-large;
  font-weight: 900;
}

.Texto {
  font-size: larger;
}