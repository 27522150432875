.Icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.DropdownBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.45);
  z-index: 4;
}

.DropdownMenuLight,
.DropdownMenuDark {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  width: 50%;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  padding-top: 1px;
  border-radius: 5px;
}
@media (max-width: 768px) {
  .DropdownMenuLight,
.DropdownMenuDark {
    width: 99vw;
  }
}
.DropdownMenuLight .DropdownItem,
.DropdownMenuDark .DropdownItem {
  width: 100%;
  margin: 0;
  overflow-wrap: break-word;
  border-bottom: 1px solid;
}

.DropdownMenuDark {
  background-color: #202225;
}
.DropdownMenuDark .DropdownItem {
  color: #fff;
  border-color: #474747;
}
.DropdownMenuDark .DropdownItem:hover {
  background-color: #3c3c3c;
}

.DropdownMenuLight {
  background-color: #f8f8f8;
}
.DropdownMenuLight .DropdownItem {
  color: #000;
  border-color: #a9a9a9;
}
.DropdownMenuLight .DropdownItem:hover {
  background-color: #d3d3d3;
}

.DropdownMenuSmall {
  width: 30% !important;
}