.RespostaProntaDark,
.RespostaProntaLight {
  display: inline-block;
  margin: 5px;
  padding: 3px 5px;
  border-radius: 10px;
  font-size: 12px;
}
.RespostaProntaDark:hover,
.RespostaProntaLight:hover {
  cursor: pointer;
  color: #fff;
  background-color: #673ab7;
}

.RespostaProntaLight {
  color: #593196;
  font-weight: 600;
  border: 1px solid #593196;
}

.RespostaProntaDark {
  background-color: #593196;
}

.Padrao {
  color: #fff;
  background-color: #c34858;
  border: none;
}
.Padrao:hover {
  cursor: pointer;
  color: #fff;
  background-color: #d15869;
}