.ToggleWrapper {
  position: relative;
  overflow: hidden;
}
.ToggleWrapper .Toggle {
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: 45px;
  height: 25px;
  background-color: #b6ebfd;
  border-radius: 84px;
  transition: background-color 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.ToggleWrapper .Toggle:before {
  content: "Dia";
  position: absolute;
  left: -50px;
  top: 0;
  font-size: medium;
  color: #593196;
}
.ToggleWrapper .Toggle:after {
  content: "Noite";
  position: absolute;
  right: -60px;
  top: 0;
  font-size: medium;
  color: lightgray;
}
.ToggleWrapper .ToggleHanddler {
  display: block;
  position: relative;
  z-index: 1;
  top: 3px;
  left: 3px;
  width: 19px;
  height: 19px;
  background-color: #ffcf96;
  border-radius: 50px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  transition: all 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transform: rotate(-45deg);
}
.ToggleWrapper .ToggleHanddler .Crater {
  position: absolute;
  background-color: #e8cda5;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  border-radius: 100%;
}
.ToggleWrapper .ToggleHanddler .Crater1 {
  top: 9px;
  left: 5px;
  width: 2px;
  height: 2px;
}
.ToggleWrapper .ToggleHanddler .Crater2 {
  top: 14px;
  left: 11px;
  width: 3px;
  height: 3px;
}
.ToggleWrapper .ToggleHanddler .Crater3 {
  top: 5px;
  left: 12px;
  width: 4px;
  height: 4px;
}
.ToggleWrapper .Star {
  position: absolute;
  background-color: #ffffff;
  transition: all 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  border-radius: 50%;
}
.ToggleWrapper .Star1 {
  top: 5px;
  left: 17px;
  z-index: 0;
  width: 15px;
  height: 2px;
}
.ToggleWrapper .Star2 {
  top: 9px;
  left: 14px;
  z-index: 1;
  width: 15px;
  height: 2px;
}
.ToggleWrapper .Star3 {
  top: 13px;
  left: 20px;
  z-index: 0;
  width: 15px;
  height: 2px;
}
.ToggleWrapper .Star4,
.ToggleWrapper .Star5,
.ToggleWrapper .Star6 {
  opacity: 0;
  transition: all 300ms 0 cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.ToggleWrapper .Star4 {
  top: 15px;
  left: 5px;
  z-index: 0;
  width: 1px;
  height: 1px;
  transform: translate3d(1px, 0, 0);
}
.ToggleWrapper .Star5 {
  top: 16px;
  left: 8px;
  z-index: 0;
  width: 1px;
  height: 1px;
  transform: translate3d(1px, 0, 0);
}
.ToggleWrapper .Star6 {
  top: 13px;
  left: 14px;
  z-index: 0;
  width: 1px;
  height: 1px;
  transform: translate3d(1px, 0, 0);
}
.ToggleWrapper input:checked + .Toggle {
  background-color: #3f7cd1;
}
.ToggleWrapper input:checked + .Toggle:before {
  color: #4d4d4d;
}
.ToggleWrapper input:checked + .Toggle:after {
  color: #ffffff;
}
.ToggleWrapper input:checked + .Toggle .ToggleHanddler {
  background-color: #ffe5b5;
  transform: translate3d(20px, 0, 0) rotate(0);
}
.ToggleWrapper input:checked + .Toggle .ToggleHanddler .Crater {
  opacity: 1;
}
.ToggleWrapper input:checked + .Toggle .Star1 {
  width: 1px;
  height: 1px;
}
.ToggleWrapper input:checked + .Toggle .Star2 {
  width: 2px;
  height: 2px;
  transform: translate3d(-4px, 0, 0);
}
.ToggleWrapper input:checked + .Toggle .Star3 {
  width: 2px;
  height: 2px;
  transform: translate3d(-7px, 0, 0);
}
.ToggleWrapper input:checked + .Toggle .Star4,
.ToggleWrapper input:checked + .Toggle .Star5,
.ToggleWrapper input:checked + .Toggle .Star6 {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.ToggleWrapper input:checked + .Toggle .Star4 {
  transition: all 300ms 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.ToggleWrapper input:checked + .Toggle .Star5 {
  transition: all 300ms 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.ToggleWrapper input:checked + .Toggle .Star6 {
  transition: all 300ms 400ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
}