.TituloLight,
.TituloDark {
  width: 100%;
  font-weight: 600;
  text-align: center;
}

.TituloLight span,
.TituloDark span {
  padding: 0 20px;
  z-index: 1;
}

.TituloDark {
  background: linear-gradient(#00000000 0%, 48%, #00000000 50%, #ffffff 51%, #00000000 52%, #00000000 100%);
  color: #fff;
}
.TituloDark span {
  background-color: #272828;
}

.TituloLight {
  background: linear-gradient(#ffffff00 0%, #ffffff00 48%, #00000000 50%, #000000 51%, #ffffff00 52%, #ffffff00 100%);
  color: #000;
}
.TituloLight span {
  background-color: #f8f9fa;
}

.Subtitulo {
  color: #888888;
  font-size: medium;
  padding-top: 5px;
}