.ConTooltip {
  height: 25px;
  width: 25px;
  position: relative;
  display: inline-block;
  border-radius: 9px;
  padding: 0;
  margin: 0;
  transition: all 0.3s ease-in-out;
  cursor: help;
}
@media (max-width: 767px) {
  .ConTooltip {
    display: none;
  }
}
.ConTooltip:hover .TooltipDark, .ConTooltip:hover .TooltipLight {
  visibility: visible;
  opacity: 1;
  /* transform: translateY(-10px);
  transition: 0.3s linear;
  animation: odsoky 1s ease-in-out infinite alternate; */
}

.TooltipDark,
.TooltipLight {
  visibility: hidden;
  position: absolute;
  top: 80%;
  left: -210%;
  text-align: center;
  z-index: 6;
  opacity: 0.4;
  width: 150px;
  padding: 5px 10px 0 10px;
  border: 1px solid #6f37d6;
  border-radius: 9px;
  font-size: 15px;
  transform: translateY(9px);
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 3px rgba(56, 54, 54, 0.86);
}

.TooltipLight {
  background-color: #f8f9fa;
  color: #000;
}

.TooltipDark {
  background-color: #292b2c;
  color: #fff;
}

.Left .TooltipDark,
.Left .TooltipLight {
  top: -20%;
  left: -600%;
}

.Right .TooltipDark,
.Right .TooltipLight {
  top: -20%;
  left: 145%;
}

@keyframes odsoky {
  0% {
    transform: translateY(6px);
  }
  100% {
    transform: translateY(1px);
  }
}