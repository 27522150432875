.Registrar {
    height: 100%;
    width: 100%;
    color: #fff;
    overflow-x: hidden;
  }
  .Registrar .RegistrarRow {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0 20px;
    padding-bottom: 100px;
    background-color: #593196e8;
  }
  @media (max-width: 768px) {
    .Registrar .RegistrarRow {
      padding: 0 5px;
      background-color: #593196;
    }
  }
  
  body {
    zoom: 100%;
  }
  
  .Texto {
    padding-left: 100px;
  }
  @media (max-width: 768px) {
    .Texto {
      padding: 0 10px;
      text-align: center;
    }
    .Texto .Vantagens {
      text-align: left;
      margin: 0 20px;
    }
  }
  .Texto h1 {
    font-weight: 700;
    color: #ad3646;
    padding-bottom: 70px;
  }
  .Texto h5 {
    margin-bottom: 30px;
  }
  
  .Form {
    width: 75%;
    padding: 10px 20px;
    color: #000;
    text-align: center;
    background-color: #f7f7f7;
    -webkit-box-shadow: 0px 0px 40px -10px rgba(0, 0, 0, 0.69);
    -moz-box-shadow: 0px 0px 40px -10px rgba(0, 0, 0, 0.69);
    box-shadow: 0px 0px 40px -10px rgba(0, 0, 0, 0.69);
  }
  @media (max-width: 768px) {
    .Form {
      width: 100%;
    }
  }
  
  .TextoDestacado {
    color: #fce412;
    font-size: 25px;
    font-weight: 600;
  }
  
  .ErrorMessage {
    font-size: medium;
    color: #c52339;
    text-align: right;
  }
  
  .Eye {
    position: absolute;
    right: 20px;
    top: 5px;
    z-index: 1;
  }
  .Eye:hover {
    cursor: pointer;
    color: darkgray;
  }
  
  .Rodape {
    padding-top: 5px;
    font-size: smaller;
  }