.DropZone {
  position: relative;
  width: 100%;
  height: 100%;
  border: 2px dashed #593196;
  border-radius: 10px;
  margin-bottom: 10px;
  overflow: hidden;
}
.DropZone .Input {
  display: none !important;
}
.DropZone .Label {
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: #dadada;
  color: #593196;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.DropZone .Label .Icon {
  text-align: center;
  margin-bottom: 10px;
}
.DropZone .Label .Delete {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 2;
  color: #c34858;
  cursor: pointer;
}
.DropZone .Label .Title {
  width: 100%;
  padding: 0 5px;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
}
.DropZone .Label .Subtitulo {
  padding: 0 5px;
  font-size: 12px;
  text-align: center;
}
.DropZone .Label .Quantidade {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 20px;
  border: 1px solid;
  border-radius: 50%;
  padding: 0 10px;
}
.DropZone .Label .Imagem {
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-select: none;
}
.DropZone .Label .ImageTitle {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #ffffff99;
  color: #593196;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
}
.DropZone .Label .ArrowLeft,
.DropZone .Label .ArrowRight {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  border-radius: 50%;
  background-color: #ffffffbd;
  color: #593196bd;
  cursor: pointer;
  user-select: none;
}
.DropZone .Label .ArrowLeft:hover,
.DropZone .Label .ArrowRight:hover {
  background-color: #e9e9e9;
}
.DropZone .Label .ArrowLeft {
  left: 5px;
}
.DropZone .Label .ArrowRight {
  right: 5px;
}
.DropZone .Hoverble:hover {
  background-color: #cacaca;
}

.Dark .Label {
  background-color: #373a3a;
  color: #fff;
}
.Dark .Label .Quantidade {
  border-color: #fff;
}
.Dark .Hoverble:hover {
  background-color: #404243;
}

.DaggingOver {
  border: 2px dashed #fff;
}
.DaggingOver .Label {
  background-color: #593196;
  color: #fff;
}
.DaggingOver .Label .Title {
  font-size: 24px;
}
.DaggingOver .Label .Quantidade {
  border-color: #593196;
}

.Spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}