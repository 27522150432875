.Wrapper {
  padding: 0 !important;
}
.Wrapper .Link {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 0.8rem 1rem;
  text-decoration: none;
  color: var(--text-color);
}