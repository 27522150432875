.Link {
  color: inherit;
  cursor: pointer;
  text-decoration: underline;
}
.Link:hover {
  color: #593196;
}

.EditButton {
  color: #559255;
  cursor: pointer;
  margin: 0 5px;
}
.EditButton:hover {
  color: #79cd56;
}