.CadastroNavigatorDark,
.CadastroNavigatorLight {
  position: fixed;
  z-index: 2;
  height: 50px;
  left: 190px;
  bottom: 0px;
  right: 0px;
  padding: 5px;
  -webkit-box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.2);
}
.CadastroNavigatorDark .ButtonAnt,
.CadastroNavigatorLight .ButtonAnt {
  background-color: #c34858;
  height: 100%;
  color: #fff;
}
.CadastroNavigatorDark .ButtonAnt:hover,
.CadastroNavigatorLight .ButtonAnt:hover {
  background-color: #973946;
  color: #fff;
}
@media (max-width: 768px) {
  .CadastroNavigatorDark,
.CadastroNavigatorLight {
    left: 0;
  }
}

.CadastroNavigatorLight {
  background-color: #fff;
}

.CadastroNavigatorDark {
  background-color: #242525;
}