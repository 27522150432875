.SkFadingCircle .SkCircle,
.InlineSkFadingCircle .SkCircle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.SkFadingCircle .SkCircle:before,
.InlineSkFadingCircle .SkCircle:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #593196;
  border-radius: 100%;
  -webkit-animation: SkCircleFadeDelay 1.2s infinite ease-in-out both;
  animation: SkCircleFadeDelay 1.2s infinite ease-in-out both;
}
.SkFadingCircle .SkCircle2,
.InlineSkFadingCircle .SkCircle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.SkFadingCircle .SkCircle2:before,
.InlineSkFadingCircle .SkCircle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.SkFadingCircle .SkCircle3,
.InlineSkFadingCircle .SkCircle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.SkFadingCircle .SkCircle3:before,
.InlineSkFadingCircle .SkCircle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.SkFadingCircle .SkCircle4,
.InlineSkFadingCircle .SkCircle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.SkFadingCircle .SkCircle4:before,
.InlineSkFadingCircle .SkCircle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.SkFadingCircle .SkCircle5,
.InlineSkFadingCircle .SkCircle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.SkFadingCircle .SkCircle5:before,
.InlineSkFadingCircle .SkCircle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.SkFadingCircle .SkCircle6,
.InlineSkFadingCircle .SkCircle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.SkFadingCircle .SkCircle6:before,
.InlineSkFadingCircle .SkCircle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.SkFadingCircle .SkCircle7,
.InlineSkFadingCircle .SkCircle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.SkFadingCircle .SkCircle7:before,
.InlineSkFadingCircle .SkCircle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.SkFadingCircle .SkCircle8,
.InlineSkFadingCircle .SkCircle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.SkFadingCircle .SkCircle8:before,
.InlineSkFadingCircle .SkCircle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.SkFadingCircle .SkCircle9,
.InlineSkFadingCircle .SkCircle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.SkFadingCircle .SkCircle9:before,
.InlineSkFadingCircle .SkCircle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.SkFadingCircle .SkCircle10,
.InlineSkFadingCircle .SkCircle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.SkFadingCircle .SkCircle10:before,
.InlineSkFadingCircle .SkCircle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.SkFadingCircle .SkCircle11,
.InlineSkFadingCircle .SkCircle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.SkFadingCircle .SkCircle11:before,
.InlineSkFadingCircle .SkCircle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.SkFadingCircle .SkCircle12,
.InlineSkFadingCircle .SkCircle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.SkFadingCircle .SkCircle12:before,
.InlineSkFadingCircle .SkCircle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

.SkFadingCircle {
  position: relative;
  margin: 100px auto;
  width: 80px;
  height: 80px;
}

.InlineSkFadingCircle {
  position: absolute;
  display: inline-block;
  margin-left: 20px;
  width: 30px;
  height: 30px;
}

@-webkit-keyframes SkCircleFadeDelay {
  0%, 39%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
@keyframes SkCircleFadeDelay {
  0%, 39%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}