.ArquetipoSelecionado {
  display: inline-block;
  margin: 5px;
  padding: 3px 5px;
  background-color: #593196;
  color: #fff;
  border-radius: 10px;
  font-size: 12px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}