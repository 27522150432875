.App {
  background-color: #f8f9fa;
  color: #000;
  min-height: 100vh;
  height: 100%;
  padding: 20px 20px 20px 210px;
}
@media (max-width: 768px) {
  .App {
    padding: 0;
    padding-top: 55px;
    width: 100%;
  }
}

.NotAuthenticated {
  padding: 0px;
}

.DarkMode {
  background-color: #262828;
  color: #fff;
}

.ModoDemonstracao {
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 20px 20px 20px 210px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 4;
  border: 3px #ff3535 solid;
  overflow-y: scroll;
}

.ModoVisualizacao {
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 20px 20px 20px 210px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 5;
  border: 4px #593196 solid;
  overflow-y: scroll;
}

.ButtonModoVisualizacao {
  position: fixed !important;
  top: 0;
  right: 0;
  z-index: 6;
}