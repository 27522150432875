.Center {
    position: absolute;
    width: 100%;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;

    text-align: center;
}
