.Wrapper {
  position: relative;
  align-self: stretch;
  width: calc(100% - 2.4rem);
  min-height: 220px;
  margin: 0;
  padding-bottom: 3.4rem;
}
.Wrapper .DropWrapper {
  display: flex;
  justify-content: center;
  border: 0.2rem dashed #404242;
  border-radius: 0.5rem;
  padding-bottom: 3rem;
  width: 100%;
  height: 100%;
  max-height: 380px;
  overflow-y: auto;
  text-align: center;
}
.Wrapper .DropWrapper .ButtonsWrapper {
  position: absolute;
  display: flex;
  justify-content: space-between;
  bottom: 4rem;
  width: 100%;
  padding: 0 1rem;
}
.Wrapper .DropWrapper .ButtonsWrapper button {
  padding: 0.2rem 0.3rem;
  background-color: #79cd56;
  border: 0;
  border-radius: 0.2rem;
  color: #fff;
  font-size: 0.8rem;
}
.Wrapper .DropWrapper .ButtonsWrapper button label {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
}
.Wrapper .DropWrapper .FilePreviewList {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0.5rem;
  list-style: none;
}
.Wrapper .DropWrapper .Instructions {
  align-self: center;
  font-weight: 600;
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .DropWrapper {
    width: calc(100% - 0.9rem);
    margin: 0 0.2rem;
  }
}