.Check {
  cursor: pointer;
  position: relative;
  width: 30px;
  height: 30px;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0, 0, 0);
}
.Check:before {
  content: "";
  position: absolute;
  width: 30px;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.2s ease;
}
.Check svg {
  position: relative;
  z-index: 1;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.5;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease;
}
.Check path {
  stroke-dasharray: 60;
  stroke-dashoffset: 0;
}
.Check polyline {
  stroke-dasharray: 22;
  stroke-dashoffset: 66;
}

.Small {
  width: 20px;
  height: 20px;
}

.UltraCheckbox:checked + .Check svg path {
  stroke-dashoffset: 60;
  transition: all 0.3s linear;
}
.UltraCheckbox:checked + .Check svg polyline {
  stroke-dashoffset: 42;
  transition: all 0.2s linear;
  transition-delay: 0.15s;
}

.textLabel {
  margin: 0;
  margin-left: 10px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.textLabel:hover {
  cursor: pointer;
}

.Disabled:hover label,
.Disabled:hover svg {
  cursor: not-allowed;
}