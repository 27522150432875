.NavItem {
  padding: 0 0.7rem;
  text-decoration: none;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.NavItemNavLink {
  position: relative;
  font-size: medium;
  color: #d3d3d3;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s;
}
.NavItemNavLink:hover {
  color: #fff;
  text-decoration: none;
}
.NavItemNavLink::after {
  position: absolute;
  content: "";
  top: 105%;
  left: 0;
  width: 100%;
  height: 2px;
  background: #fff;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s;
}
.NavItemNavLink:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}