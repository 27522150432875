.FormGroup {
  width: 100%;
  position: relative;
  margin-bottom: 15px;
  padding: 15px 0 0;
}

.Titulo {
  position: absolute;
  top: 0;
  display: block;
  font-size: 1rem;
  color: #9b9b9b;
}

.Button {
  position: relative;
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  text-align: left;
  font-size: 18px;
  color: inherit;
  padding: 7px 5px 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}
.Button:focus {
  outline: none;
  border-image: linear-gradient(to right, #9367e6, #6f37d6);
  border-image-slice: 1;
}
.Button .Icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.DropdownMenuLight,
.DropdownMenuDark {
  position: absolute;
  right: 0;
  width: 300px;
  max-height: 220px;
  z-index: 5;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  border-radius: 5px;
  text-align: center;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
}
.DropdownMenuLight .DropdownItem,
.DropdownMenuDark .DropdownItem {
  width: 100%;
  margin: 0;
  overflow-wrap: break-word;
  border-bottom: 1px solid;
  cursor: pointer;
}

.DropdownMenuDark {
  background-color: #262828;
  border: 1px solid #474747;
}
.DropdownMenuDark .DropdownItem {
  color: #fff;
  border-color: #474747;
}
.DropdownMenuDark .DropdownItem:hover {
  background-color: #3c3c3c;
}

.DropdownMenuLight {
  background-color: #f8f8f8;
  border: 1px solid #6f37d6;
}
.DropdownMenuLight .DropdownItem {
  color: #000;
  border-color: #6f37d6;
}
.DropdownMenuLight .DropdownItem:hover {
  background-color: #d3d3d3;
}

.Small {
  margin-bottom: 10px;
}
.Small .Button {
  font-size: 0.8rem;
}

.Inline {
  padding: 0;
}
.Inline .Button {
  padding: 0 5px 0 0;
}

.Invalid {
  color: #c34858;
  border-color: #c34858;
}
.Invalid:focus {
  outline: none;
  border-image: linear-gradient(to right, #c34858, #ee4e63);
  border-image-slice: 1;
}