.SideDrawerButton {
  width: 100%;
  border-bottom: 1px solid #fff;
  padding: 5px 10px;
  list-style: none;
  color: #fff !important;
  font-size: 22px;
  font-weight: 350;
  text-decoration: none !important;
}
.SideDrawerButton:first-child {
  border-top: 1px solid #fff;
}

.SideDrawerButtonActive {
  background-color: #545454;
}