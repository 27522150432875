.Wrapper {
  width: 100%;
  height: 100%;
}
@media (max-width: 768px) {
  .Wrapper {
    overflow-x: scroll;
    padding: 0;
  }
}
.Wrapper .Table {
  border-collapse: separate;
  border-spacing: 0;
}
.Wrapper .Table thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  border-top: 1px solid;
  border-bottom: 1px solid;
  background-color: #fff;
  text-align: center;
  color: #000;
}
.Wrapper .Table tbody td {
  text-align: center;
  border-bottom: 1px solid #000;
}
.Wrapper .Bordered thead th {
  border-right: 1px solid #000;
}
.Wrapper .Bordered thead th:first-child {
  border-left: 1px solid #000;
}
.Wrapper .Bordered tbody td {
  border-right: 1px solid #000;
}
.Wrapper .Bordered tbody td:first-child {
  border-left: 1px solid #000;
}
.Wrapper .Hoverble tbody tr {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.Wrapper .Hoverble tbody tr:hover {
  background-color: lightgrey;
}
.Wrapper .Spriped tbody:nth-child(even) {
  background-color: rgba(169, 169, 169, 0.4);
}

.Small {
  font-size: 0.9rem;
}
.Small .thead th {
  padding: 4px;
}
.Small .tbody td {
  padding: 4px;
}

.Fixed {
  overflow-y: auto;
}

.Dark .Table {
  color: #fff;
}
.Dark .Table thead th {
  color: #fff;
  background-color: #363838;
}
.Dark .Table tbody td {
  border-color: #fff;
  background-color: transparent;
}
.Dark .Bordered thead th {
  border-color: #fff !important;
}
.Dark .Bordered tbody td {
  border-color: #fff !important;
}
.Dark .Hoverble tbody tr:hover {
  background-color: #3b3e3e;
}