.Link {
  color: #888888;
  font-size: medium;
  text-decoration: none !important;
  transition: 0.3s;
}
.Link :hover {
  font-size: larger;
}

.Parent {
  position: relative;
  height: 70vh;
}
.Parent .Center {
  position: absolute;
  width: 100%;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.Parent .Center span {
  color: green;
  cursor: pointer;
}
.Parent .Center span:hover {
  text-decoration: underline;
}