.BarraPesquisa {
  position: relative;
}

.BotaoPesquisa {
  position: absolute;
  right: 0;
  border-width: 2px;
}

.CleanButton {
  color: #c34858;
  position: absolute;
  right: 50px;
  margin-top: 10px;
  cursor: pointer;
}