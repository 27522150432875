.Card {
  width: 100%;
  margin: 30px 0;
  padding: 10px 15px;
  background-color: #f0f0f0;
  border: 1px solid #673ab7;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 10px -21px rgba(0, 0, 0, 0.69);
  -moz-box-shadow: 0px 0px 10px -21px rgba(0, 0, 0, 0.69);
  box-shadow: 0px 0px 10px -21px rgba(0, 0, 0, 0.69);
}
.Card:first-child {
  margin-top: 0;
}
.Card .Link {
  padding: 30px 20px;
}
.Card .Link a {
  color: inherit;
  font-size: 20px;
}
@media (max-width: 768px) {
  .Card .Link {
    padding: 0 15px;
  }
  .Card .Link a {
    color: inherit;
    font-size: 15px;
    font-weight: 700;
  }
}
.Card .Wrapper {
  width: 150;
  height: 70px;
  overflow: hidden;
}
.Card .Wrapper img {
  height: 100%;
}
@media (max-width: 768px) {
  .Card .Wrapper {
    display: none;
  }
}
.Card .ImagemPrincipal {
  text-align: center;
}
@media (max-width: 768px) {
  .Card .ImagemPrincipal {
    display: none;
  }
}
.Card .Historico {
  background-color: #f8f9fa;
  border: 1px solid #683ab7;
  border-radius: 4px;
  padding: 20px;
  height: 20vh;
  overflow: auto;
  margin-bottom: 10px;
}
.Card .Historico .BubblePergunta,
.Card .Historico .BubbleResposta {
  position: relative;
  display: inline-block;
  padding: 5px;
  margin: 2px 0;
  border-radius: 5px;
  font-size: 16px;
}
.Card .Historico .BubblePergunta::after,
.Card .Historico .BubbleResposta::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 0;
  height: 0;
  border: 11px solid transparent;
  border-top: 0;
  margin-top: -5.5px;
  z-index: 1;
}
.Card .Historico .BubblePergunta .Data,
.Card .Historico .BubbleResposta .Data {
  font-size: 11px;
  margin-left: 8px;
  bottom: 0;
}
.Card .Historico .BubblePergunta {
  background-color: #e0e0e0;
}
.Card .Historico .BubblePergunta::after {
  left: 0;
  border-left: 0;
  margin-left: -11px;
  border-right-color: #e0e0e0;
}
.Card .Historico .BubbleResposta {
  background-color: #673ab7;
  color: #fff;
  float: right;
}
.Card .Historico .BubbleResposta::after {
  right: 0;
  border-right: 0;
  margin-right: -11px;
  border-left-color: #673ab7;
}
@media (max-width: 768px) {
  .Card .Historico {
    padding: 15px 10px 15px 10px;
    height: 40vh;
  }
  .Card .Historico .BubblePergunta,
.Card .Historico .BubbleResposta {
    margin-bottom: 10px;
  }
}
.Card .Textarea {
  background-color: #f8f9fa;
}
.Card .Textarea:focus {
  background-color: #f8f9fa;
}
.Card .SendButton {
  display: block;
  position: absolute;
  top: 50%;
  transition: color 0.2s;
  transform: translateY(-50%);
  cursor: pointer;
  color: #79cd56;
}
.Card .SendButton:hover {
  color: #8ad669;
}
.Card .Usuario {
  padding: 10px 0;
  font-size: small;
}

.Dark {
  background-color: #303333;
  border: 1px solid #673ab7;
}
.Dark .Historico {
  border: 1px solid #ffffff50;
  background-color: #404242;
}
.Dark .Historico .BubblePergunta {
  border: 1px solid #a9a9a9;
  background-color: #363838;
}
.Dark .Historico .BubblePergunta::after {
  border-right-color: #363838;
}
.Dark .Historico .BubbleResposta {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #000;
}
.Dark .Historico .BubbleResposta::after {
  border-left-color: #f8f9fa;
}
.Dark .Textarea {
  background-color: transparent;
  border-color: #ffffff50;
}
.Dark .Textarea:focus {
  background-color: transparent;
  border-color: #79cd56;
}
.Dark .SendButton {
  color: #fff;
}

.MercadoLivre {
  border-color: #ffd101;
}

.DeleteIcon {
  color: #c34858;
  cursor: pointer;
}